// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NJ9xgC8wJ4fHBVUkbKfA{background:rgba(0,0,0,0);transition:background-color .25s ease}.owLlCpZI_0TVVYBvzOVq{background:#dcf9f4;transition:background-color .25s ease}.y2LTNLhn5G3hGHt0PQln{color:#387765;font-size:16px;font-weight:bold;margin-left:14px}.rsva5TNts0648HWS8nT9{align-items:center;display:flex}.XN_0YIpCNtAMnAROXqMw{align-items:center;border-radius:4px;border:1px dashed #07c;display:flex;height:88px;justify-content:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noBackground": "NJ9xgC8wJ4fHBVUkbKfA",
	"showBackground": "owLlCpZI_0TVVYBvzOVq",
	"text": "y2LTNLhn5G3hGHt0PQln",
	"textContainer": "rsva5TNts0648HWS8nT9",
	"uploadContainer": "XN_0YIpCNtAMnAROXqMw"
};
export default ___CSS_LOADER_EXPORT___;
